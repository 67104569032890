import { inject, Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  PreferenceParams,
  Preferences,
} from '@/app/core/models/preferences.model';
import {
  DashboardParams,
  DashboardParsed,
} from '@/app/core/models/dashboard.model';
import { buildDashboard } from '@/mocks/data/dashboard';
import { Filter, User } from '@/app/core/models/user.model';
import { UserService } from '@/app/core/services/user.service';
import { AuthService } from '@/app/core/services/auth.service';
import { StaticDataService } from '@/app/core/services/staticData.service';
import { AnnouncementsService } from '@/app/core/services/announcements.service';
import { Announcement } from '@/app/core/models/announcement.model';

@Injectable({ providedIn: 'root' })
export class DashboardPageService {
  private usersService = inject(UserService);
  private authService = inject(AuthService);
  private staticDataService = inject(StaticDataService);
  private announcementService = inject(AnnouncementsService);
  user$!: Observable<User>;
  selectedFilter$!: Observable<Filter>;
  dashboard$!: Observable<DashboardParsed>;
  preferences$!: Observable<Preferences>;
  messages$!: Observable<Announcement[]>;
  isLoading = false;

  getPreferences() {
    const username$ = this.authService.getCurrentUsername();
    const applicationId$ = this.authService.getApplicationId();
    const preferenceCode$ = this.authService.getPreferenceCode();
    const preferencesParams$ = combineLatest([
      applicationId$,
      preferenceCode$,
      username$,
    ]).pipe(
      map(
        ([applicationID, preferenceCode, username]): PreferenceParams => ({
          applicationID,
          preferenceCode,
          username,
        }),
      ),
    );
    return preferencesParams$.pipe(
      switchMap((params) => this.usersService.getPreferences(params)),
    );
  }

  getDashboardData() {
    const filterId$ = this.usersService
      .getCurrentUser()
      .pipe(map((user) => user?.DEFAULT_FILTER_ID ?? -1))
      .pipe(tap(() => (this.isLoading = true)));
    const username$ = this.authService.getCurrentUsername();
    const dashboardParams$ = combineLatest([filterId$, username$]).pipe(
      map(([filterId, username]): DashboardParams => ({ filterId, username })),
    );
    return dashboardParams$
      .pipe(
        switchMap((params) => this.staticDataService.getDashboardData(params)),
        map((data) =>
          data.reduce(
            (previous, item) => ({ ...previous, [item.Key]: item.Value }),
            buildDashboard(),
          ),
        ),
      )
      .pipe(tap(() => (this.isLoading = false)));
  }
  getAnnouncements() {
    return this.authService
      .getCurrentUsername()
      .pipe(
        switchMap((username) =>
          this.announcementService.getAnnouncements(username),
        ),
      );
  }
}
